@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);

h2 {
    margin-top: 20px;
}

mark {
    background-color: white;
    padding: 0px 3px;
}

.wrapper {
    padding: 100px;
    margin: 0 0 10px 0;
    text-align: left;
    height: 40vh;
    background-size: cover;
    transition: all 0.4s ease;
}

.dateStamp {
    margin: 0 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
}

.articleText {
    margin: 0 10% 30px 20%;
    width: 60%;
    max-width: 800px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    transition: all 0.4s ease;
}

@media only screen and (max-width: 1000px) {

    /* For tablets: */
    .articleText {
        margin: 0 10% 30px 10%;
        width: 80%;
        max-width: 800px;
        padding-left: 0px;
        border: 0;
    }
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .articleText {
        margin: 0 5% 30px 5%;
        width: 90%;
    }

    .wrapper {
        padding: 5%;
    }
}

.otherNewsTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* specify number of lines here */
    -webkit-box-orient: vertical;
}
.topNav {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  align-items: center !important;
  background-color: #1E2024 !important;
  width: 100%;
  margin: 0;

}


@media (min-width: 992px) {
  .topNav {
    padding-left: 3rem !important;
  }
}

@media (max-width: 500px) {
  .mobileViewCards {
    display: none;
  }
}

@media (min-width: 992px) {
  .topNav {
    padding-right: 3rem !important;
  }
}

.border-right {
  border-right: 1px solid #E4E7ED !important;
}

.linkColor {
  color: #9A9DA2 !important;
}

.trending {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  align-items: center !important;
}

.section-title {
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #dee2e6;
  border-left: 5px solid var(--primary);
}

.badge-primary {
  color: #FFFFFF;
  background-color: var(--primary);
}

.badge {
  border-radius: 0;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.show-offNav-fullHeader {
    border-bottom: 200px solid #000;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)
}

.offNav {
    top: 0;
    position: absolute;
    transform: translateY(-600px);
    background-color: black;
    height: 200px;
    width: 100% !important;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
    overflow-x: hidden;
    overflow-y: hidden;
}

.offNav-a:hover {
    color: #2AA296;
}

.show-offNav {
    transform: translateY(0px) !important;
}

ul .unstyled {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dropList {
    color: white;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    margin-top: 45px !important;
    margin-left: 8% !important;
    width: 84.5%;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 650px) {
    .dropList {
        margin-top: 2px !important;
        margin-left: 50px !important;
        line-height: 42px !important;
        -webkit-column-count: 1;
        padding: 0;
        -moz-column-count: 1;
        column-count: 1;
    }
}

.unstyled li {
    list-style-type: none;
    margin-bottom: 0;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #369;
}

.dropList li a {
    color: white;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.topHead-btn {
    background-color: var(--secondary);
    color: white;
    position: absolute;
    right: 8%;
    top: 8px;
    padding: 0px 0px 0px 0px !important;
    width: 126px;
    display: flex !important;
    justify-content: center;
    outline: 1px solid white;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
    z-index: 1;
    -webkit-animation: fadein 6s;
    -moz-animation: fadein 6s;
    -ms-animation: fadein 6s;
    -o-animation: fadein 6s;
    animation: fadein 6s;
}

.login-btn {
    background-color: var(--secondary);
    color: white;
    position: absolute;
    right: 8%;
    top: 8px;
    height: 38px;
    padding: 0px 0px 0px 0px !important;
    width: 126px;
    display: flex !important;
    justify-content: center;
    outline: 1px solid white;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
    z-index: 1;
    animation: fadein 6s;
}



.topHead-btn:hover {
    cursor: pointer;
    text-decoration: underline;
}

.login-btn:hover {
    cursor: pointer;
    text-decoration: underline;
}

.login-btn a {
    color: #fff !important;
}

.login-btn .menuText {
    margin: 0 !important;
}

.login-btn.change-btn {
    transform: translateY(130px);
    right: 17%;
    top: 8px;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
}


@media (max-width: 650px) {
    .topHead-btn {
        display: none !important;
    }

    .login-btn {
        display: none !important;
    }

    .show-offNav-fullHeader {
        display: none;
    }
}

.topHead-btn.change-btn {
    transform: translateY(130px);
}

.login-btn.login-chang-btn {
    transform: translateY(130px);
}

.hamBox {
    /* margin-left: 18px; */
    margin-top: 5px;
    margin-bottom: 5px;
}

.bar1, .bar2, .bar3 {
    width: 23px;
    height: 4px;
    background-color: #FFF;
    margin: 4px 0;
    transition: 0.8s;
}

.change-btn .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 4px);
    -ms-transform: rotate(-45deg) translate(-5px, 4px);
    transform: rotate(-45deg) translate(-5px, 4px);
}

.change-btn .bar2 {
    opacity: 0;
}

.change-btn .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    -ms-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
}

.menuText {
    text-transform: none;
    margin-left: 14px;
    margin-top: 2px;
}

.eta .light {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    text-rendering: auto;
}


@media screen and (max-width: 350px) {
    .mobile-hidden {
        display: none;
    }
}

/* .desktop-hidden {
    display: none;
}

@media screen and (min-width: 651px) and (max-width: 919px) {
    .desktop-hidden {
        display: inherit;
    }
} */

@media screen and (min-width: 651px) and (max-width: 919px) {
    .tablet-hidden {
        display: none !important;
    }
}

.white {
    color: #fff !important;
}

.eta {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0;
    text-rendering: auto;
}


.field {
    background: #eee none;
    padding: 8px 10px;
    border: 1px solid #d5d5d5;
    font: normal 17px/24px 'Trade Gothic W01 Roman', Arial, Helvetica, Verdana, sans-serif;
    line-height: 1.2;
    width: 100%;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .15);
    vertical-align: middle;
    -webkit-border-radius: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mainSearchField {
    background-color: rgba(0, 0, 0, 0) !important;
    padding-left: 18px !important;
    border: 0px !important;
    width: 242px !important;
    border-radius: 0;
    -webkit-appearance: none;
}

.btn-submit {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -webkit-font-smoothing: antialiased;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    padding: 0 !important;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    -moz-user-select: none;
    background-color: var(--secondary);
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding: 9px 11px 9px 11px;
    margin: 0;
    position: relative;
    line-height: 1.2;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-transition: background .1s linear;
    -moz-transition: background .1s linear;
    -ms-transition: background .1s linear;
    -o-transition: background .1s linear;
    transition: background .1s linear;
}

.mainSearchBtn {
    width: 52px;
    overflow: hidden;
    position: relative;
}

.search-icon-svg {
    transform: rotate(-45deg);
    position: absolute;
    left: 3px;
    top: 0;
    fill: white;
}

@media screen and (max-width: 919px) {
    canvas, iframe, img, svg {
        max-width: 100%;
    }
}

svg:not(:root) {
    overflow: hidden;
}


@media (max-width: 650px) {
    .bar1, .bar2, .bar3 {
        width: 30px;
        height: 5px;
        background-color: #FFF;
        margin: 6px 0;
        transition: 0.4s;
    }
}

@media (max-width: 650px) {
    .change-btn .bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 10px);
        -ms-transform: rotate(-45deg) translate(-6px, 10px);
        transform: rotate(-45deg) translate(-6px, 10px);
    }
}

@media (max-width: 650px) {
    .change-btn .bar3 {
        -webkit-transform: rotate(45deg) translate(-6px, -9px);
        -ms-transform: rotate(45deg) translate(-6px, -9px);
        transform: rotate(45deg) translate(-6px, -9px);
    }
}

.menuText {
    text-transform: none;
    margin-left: 14px;
    margin-top: 2px;
}

@media (max-width: 340px) {
    .mobile-text-change {
        font-size: 7vw !important;
    }
}

@media((min-width:341px) and (max-width: 440px)) {
    .mobile-text-change {
        font-size: 8vw !important;
    }
}

@media((min-width:441) and (max-width: 650px)) {
    .mobile-text-change {
        font-size: 36px !important;
    }
}

@media (max-width: 650px) {
    .menuText {
        margin-left: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 650px) {
    .searchWrapper {
        width: 100%;
        height: 66px;
        background-color: rgba(0, 0, 0, 1);
    }
}

@media (max-width: 650px) {
    .mobile-searchWrapper {
        margin-left: 9.5%;
        width: 82%;
    }
}

@media screen and (max-width: 350px) {
    .desktop-hidden {
        display: inherit;
    }
}

@media (max-width: 650px) {
    .searchBox {
        display: flex;
        margin-top: 0px;
        margin-left: 0px;
        height: 48px;
        width: auto;
        outline: 1px solid white;
    }
}

@media (max-width: 650px) {
    .show-mobile-offNav {
        max-height: 5000px !important;
        padding-bottom: 40px;
    }
}

@media (max-width: 650px) {
    .mobile-offNav {
        background-color: black;
        max-height: 0px;
        width: 100% !important;
        transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

@media screen and (max-width: 350px) {
    .eta {
        font-size: 21px;
        line-height: 27px;
        letter-spacing: 0;
        text-rendering: auto;
    }
}

@media (max-width: 650px) {
    .mainSearchField {
        padding-left: 28px !important;
        width: calc(-49px + 100%) !important;
    }
}

@media (max-width: 650px) {
    .mobileMenu-btn-close {
        background-color: #000 !important;
        outline: 0px solid black !important;
        margin-left: 21px;
        margin-top: 23px;
        color: var(--primary) !important;
    }
}

@media (max-width: 650px) {
    .mobileMenu-btn-wrapper {
        width: 100%;
        height: 66px;
        padding-left: 9.5%;
        background-color: rgba(0, 0, 0, 1);
    }
}

@media (max-width: 650px) {
    .mobileMenu-close .mobileMenu-btn .hamBox {
        margin-top: 11px;
    }
}

@media (max-width: 650px) {
    .mobileMenu-btn {
        color: white;
        background-color: var(--secondary);
        padding: 0px 0px 0px 0px !important;
        width: 91%;
        height: 48px;
        display: flex !important;
        outline: 1px solid white;
        transition: margin 500ms cubic-bezier(0.4, 0.0, 0.2, 1), background 0.1s linear;
    }
}

@media (max-width: 650px) {
    .mobileMenu-btn-close .hamBox div {
        background-color: var(--primary) !important;
    }
}

.mobileMenu-btn-close .hamBox .bar1 {
    transform: rotate(-45deg) translate(-6px, 10px);
}

.mobileMenu-btn-close .hamBox .bar2 {
    opacity: 0;
}

.mobileMenu-btn-close .hamBox .bar3 {
    transform: rotate(45deg) translate(-6px, -9px);
}


@media (max-width: 650px) {
    .mobileMenu-btn-close .menuText {
        margin-top: 10px;
        color: var(--primary) !important;
    }
}

.stickHead {
    visibility: hidden;
    z-index: 200;
    display: inline-flex;
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
}

.sticky-appear {
    visibility: visible !important;
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
    -webkit-transform: translateY(0px) !important;
    -ms-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
}

.offNav-sticky {
    visibility: hidden;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    top: 60px;
    height: 200px;
    width: 100% !important;
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
    transform: translateY(-600px);
    overflow-x: hidden;
    overflow-y: hidden;
}

.stickHead-bg {
    display: inline-flex;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}

.offNav-sticky .dropList {
    margin-left: 7.9% !important;
}

.desktop-sticky-links {
    display: block !important;
}

@media (max-width: 1220px) and (min-width: 650px) {
    .desktop-sticky-links {
        display: none !important;
    }

    .stickHead-bg {
        display: inline-flex;
        justify-content: center;
    }

    .stickyHam {
        margin: 11px 0 0 !important;
    }
}

.first-stickyBtn {
    margin: 0 52px 0 auto;
}

.stickyBtn {
    margin-right: 55px;
    padding-top: 15px;
}

@media (max-width: 1220px) and (min-width: 768px) {
    .stickyBtn {
        margin-right: auto;
    }
}

@media (max-width: 1220px) and (min-width: 768px) {
    .tablet-sticky-links {
        display: block !important;
    }
}



.tablet-sticky-links {
    display: none;
}

.stickHead a {
    color: white;
}

.stickySearch {
    background-color: rgba(255, 255, 255, 0.7) !important;
    display: flex;
    margin: 11px 15px 0 0;
    width: 283px;
    height: 38px;
    outline: 1px solid white;
}

@media (max-width: 1220px) and (min-width: 920px) {
    .stickySearch {
        margin: 11px 0 0 0;
    }
}

.nav-logo {
    width: fit-content;
    height: 100%;
    display: flex;
    font-size: 28px;
    font-weight: 700;
    align-items: center;
    margin-left: 50px;
}

.stickySearch .mainSearchField {
    width: 242px !important;
}

.show-offNav-sticky {
    transform: translateY(0px) !important;
    visibility: visible !important;
}

.stickyHam {
    color: white;
    background-color: var(--secondary);
    height: 38px;
    padding: 0px 0px 0px 0px !important;
    width: 126px;
    margin: 11px auto 0 0;
    display: flex !important;
    justify-content: center;
    outline: 1px solid white;
}

.stickyHam:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1220px) and (min-width: 768px) {
    .stickyHam {
        margin: 11px 15px 0 15px !important;
    }
}

@media (max-width: 650px) {
    .nav-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 28px;
        font-weight: 700;
        align-items: center;
        margin: 0;
    }
}
.chat-log {
    overflow: auto;
    height: calc(75vh);
}

.chat-log_item {
    background: #eaeaea;
    padding: 10px;
    margin: 10px;
    max-width: 95%;
    min-width: 25%;
    float: left;
    font-size: 13px;
    /* border-radius: 0 20px 20px 20px; */
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    clear: both;
}

/* .chat-log_item.chat-log_item-own {
    margin-right: 0.7rem;
    border-radius: 20px 0 20px 20px;
} */

.chat-form {
    background: #DDDDDD;
    padding: 40px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.chat-log_author {
    color: #3b3b3b;
    margin: 0 auto 0em;
    font-size: 12px;
    font-weight: bold;
}

.chat-log_message {
    color: #3b3b3b;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.chat-log_time {
    color: #3b3b3b;
    margin: 0 auto .5em;
    font-size: 12px;
    opacity: 0.5;
}
.titleErr {
    border: 1px solid #df4554;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100% !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 800px !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #df4554 !important;
} */

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-style: none !important;
}

.css-26l3qy-menu {
    display: block !important;
    /* background: #000; */
}

.css-1s2u09g-control {
    height: 48px;
}

.my-custom-react-select__menu {
    z-index: 99999999999;
    position: absolute;
}

.edit_hover_class {
    width: fit-content;
    position: relative;
}

.edit_hover_class a {
    visibility: hidden;
    position: absolute;
    right: 5px;
}

.edit_hover_class:hover a {
    visibility: visible;
}
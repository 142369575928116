ul .unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropList {
  color: white;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  margin-top: 45px !important;
  margin-left: 8% !important;
  width: 84.5%;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 650px) {
  .dropList {
    margin-top: 2px !important;
    margin-left: 50px !important;
    line-height: 42px !important;
    -webkit-column-count: 1;
    padding: 0;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.unstyled li {
  list-style-type: none;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #369;
}

.dropList li a {
  color: white;
  font-size: 18px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topHead-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 650px) {
  .topHead-btn {
    display: none !important;
  }
}

/* .topHead-btn.change-btn {
  transform: translateY(523px);
} */

.hamBox {
  /* margin-left: 18px; */
  margin-top: 5px;
  margin-bottom: 5px;
}

.bar1, .bar2, .bar3 {
  width: 23px;
  height: 4px;
  background-color: #FFF;
  margin: 4px 0;
  transition: 0.8s;
}

.change-btn .bar1 {
  -webkit-transform: rotate(-45deg) translate(-56x, 4px);
  -ms-transform: rotate(-45deg) translate(-5px, 4px);
  transform: rotate(-45deg) translate(-5px, 4px);
}

.change-btn .bar2 {
  opacity: 0;
}

.change-btn .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  -ms-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

.menuText {
  text-transform: none;
  margin-left: 14px;
  margin-top: 2px;
}

.eta .light {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  text-rendering: auto;
}

.homeContent {
  background-color: black;
  transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1) !important;
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 595px;
  height: calc(100vh - 26px);
}

@media (max-width: 650px) {
  .homeContent {
    height: 100%;
    display: block;
  }
}

/* .show-offNav-homeContent {
  transform: translateY(400px) !important;
} */

@media (max-width: 919px) and (min-width: 650px) {
  .homeContent {
    height: calc(100vh - 52px);
  }
}

.vidBox {
  background-color: black;
  min-height: 100%;
  min-width: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 350px) {
  .mobile-hidden {
    display: none;
  }
}

video {
  object-fit: contain;
}

.mobileImg {
  width: 100%;
  height: 325px;
  background-image: url(../../../Assets//Images/background1.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #000;
}

.desktop-hidden {
  display: none;
}

@media screen and (min-width: 651px) and (max-width: 919px) {
  .desktop-hidden {
    display: inherit;
  }
}

@media screen and (min-width: 651px) and (max-width: 919px) {
  .tablet-hidden {
    display: none !important;
  }
}

.fadeBox {
  position: absolute;
  top: 120px;
  left: 15%;
  -webkit-animation: fadein 6s;
  -moz-animation: fadein 6s;
  -ms-animation: fadein 6s;
  -o-animation: fadein 6s;
  animation: fadein 6s;
}

@media (max-width: 650px) {
  .fadeBox {
    background-color: rgba(0, 0, 0, 1);
    position: relative;
    top: 0;
    left: 0 !important;
    width: 100%;
    height: 100%;
    -webkit-animation: fadein 0s;
    -moz-animation: fadein 0s;
    -ms-animation: fadein 0s;
    -o-animation: fadein 0s;
    animation: fadein 0s;
  }
}

@media (max-width: 919px) and (min-width: 650px) {
  .fadeBox {
    left: 10%;
  }
}

@media (max-height: 919px) and (max-width: 1220px) {
  .fadeBox {
    left: 10%;
  }
}

.blackBox {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 38px 26px 38px;
  border-radius: 5px;
}

@media (max-width: 650px) {
  .blackBox {
    background-color: rgba(0, 0, 0, 1);
    height: 100%;
    padding: 0px;
  }
}

.h1-header {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 100%;
}

.blackBox img {
  padding-bottom: 20px;
}

.blackBox .title-card {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 10px;
}



@media (max-width: 650px) {
  .blackBox img {
    width: 82%;
    height: auto;
    max-width: 340px;
    display: block;
    margin: 0 auto;
    padding-top: 26px;
  }
}

fieldset, img {
  border: 0;
}

.mainButton-box {
  margin-bottom: 12px;
}

.mainButton-box-first {
  margin-top: -5px;
}

.mainButton {
  overflow: hidden;
  white-space: nowrap;
  outline: 1px solid #48c4b7;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 16px;
  padding-bottom: 4px;
  align-items: center;
}

.eta:hover {
  color: #18bba2 !important;
}


@media (max-width: 650px) {
  .mainButton {
    height: 44px;
    width: auto;
    padding-left: 28px;
    padding-bottom: 4px;
  }
}

@media (max-width: 650px) {
  .mainButton-box {
    margin-left: 9.5%;
    margin-bottom: 18px;
    width: 82%;
  }
}

@media (max-width: 650px) {
  .mainButton-box-first {
    margin-top: 6px;
  }
}

.alpha {
  font-size: 61px;
  line-height: 63px;
  letter-spacing: 0;
  text-rendering: auto;
}

@media screen and (min-width: 651px) and (max-width: 919px) {
  .alpha {
    font-size: 47px;
    line-height: 47px;
    letter-spacing: -1px;
    text-rendering: optimizeLegibility;
  }
}

.white {
  color: #fff !important;
}

.eta {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  text-rendering: auto;
}

.searchBox {
  background-color: rgba(255, 255, 255, 0.7) !important;
  display: flex;
  margin-top: 40px;
  /* margin-left: 38px; */
  width: 283px;
  height: 38px;
  outline: 1px solid white;
}

.field {
  background: #eee none;
  padding: 8px 10px;
  border: 1px solid #d5d5d5;
  font: normal 17px/24px 'Trade Gothic W01 Roman', Arial, Helvetica, Verdana, sans-serif;
  line-height: 1.2;
  width: 100%;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, .15);
  vertical-align: middle;
  -webkit-border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mainSearchField {
  background-color: rgba(0, 0, 0, 0) !important;
  padding-left: 18px !important;
  border: 0px !important;
  width: 242px !important;
  border-radius: 0;
  -webkit-appearance: none;
}

.btn-submit {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  padding: 0 !important;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  -moz-user-select: none;
  background-color: var(--secondary);
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 9px 11px 9px 11px;
  margin: 0;
  position: relative;
  line-height: 1.2;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: background .1s linear;
  -moz-transition: background .1s linear;
  -ms-transition: background .1s linear;
  -o-transition: background .1s linear;
  transition: background .1s linear;
}

.mainSearchBtn {
  width: 52px;
  overflow: hidden;
  position: relative;
}

.search-icon-svg {
  transform: rotate(-45deg);
  position: absolute;
  left: 3px;
  top: 0;
  fill: white;
}

@media screen and (max-width: 919px) {
  canvas, iframe, img, svg {
    max-width: 100%;
  }
}

svg:not(:root) {
  overflow: hidden;
}

@media (max-width: 650px) {
  .mobileMenu-btn {
    color: white;
    background-color: var(--secondary);
    padding: 0px 0px 0px 0px !important;
    width: 82%;
    height: 48px;
    display: flex !important;
    outline: 1px solid white;
    transition: margin 500ms cubic-bezier(0.4, 0.0, 0.2, 1), background 0.1s linear;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn:hover {
    cursor: pointer;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn .hamBox {
    margin-left: 28px;
    margin-top: 4px;
    margin-bottom: 0px;
  }
}

@media (max-width: 650px) {
  .bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: #FFF;
    margin: 6px 0;
    transition: 0.4s;
  }
}

@media (max-width: 650px) {
  .change-btn .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 10px);
    -ms-transform: rotate(-45deg) translate(-6px, 10px);
    transform: rotate(-45deg) translate(-6px, 10px);
  }
}

@media (max-width: 650px) {
  .change-btn .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -9px);
    -ms-transform: rotate(45deg) translate(-6px, -9px);
    transform: rotate(45deg) translate(-6px, -9px);
  }
}

.menuText {
  text-transform: none;
  color: #fff !important;
  margin-left: 14px;
  margin-top: 2px;
}

.menuText:hover {
  color: #fff !important;
}

@media (max-width: 340px) {
  .mobile-text-change {
    font-size: 7vw !important;
  }
}

@media((min-width:341px) and (max-width: 440px)) {
  .mobile-text-change {
    font-size: 8vw !important;
  }
}

@media((min-width:441) and (max-width: 650px)) {
  .mobile-text-change {
    font-size: 36px !important;
  }
}

@media (max-width: 650px) {
  .menuText {
    margin-left: 21px;
    margin-bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .searchWrapper {
    width: 100%;
    height: 66px;
    background-color: rgba(0, 0, 0, 1);
  }
}

@media (max-width: 650px) {
  .mobile-searchWrapper {
    margin-left: 9.5%;
    width: 82%;
  }
}

@media screen and (max-width: 350px) {
  .desktop-hidden {
    display: inherit;
  }
}

@media (max-width: 650px) {
  .searchBox {
    display: flex;
    margin-top: 0px;
    margin-left: 0px;
    height: 48px;
    width: auto;
    outline: 1px solid white;
  }
}

@media (max-width: 650px) {
  .show-mobile-offNav {
    max-height: 5000px !important;
    padding-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .mobile-offNav {
    background-color: black;
    max-height: 0px;
    width: 100% !important;
    transition: all 0.8s cubic-bezier(0.4, 0.0, 0.2, 1);
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 350px) {
  .eta {
    font-size: 21px;
    line-height: 27px;
    letter-spacing: 0;
    text-rendering: auto;
  }
}

@media (max-width: 650px) {
  .mainSearchField {
    padding-left: 28px !important;
    width: calc(-49px + 100%) !important;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn-close {
    background-color: #000 !important;
    outline: 0px solid black !important;
    margin-left: 21px;
    margin-top: 23px;
    color: var(--primary) !important;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn-wrapper {
    width: 100%;
    height: 66px;
    padding-left: 9.5%;
    background-color: rgba(0, 0, 0, 1);
  }
}

@media (max-width: 650px) {
  .mobileMenu-close .mobileMenu-btn .hamBox {
    margin-top: 11px;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn {
    color: white;
    background-color: var(--secondary);
    padding: 0px 0px 0px 0px !important;
    width: 91%;
    height: 48px;
    display: flex !important;
    outline: 1px solid white;
    transition: margin 500ms cubic-bezier(0.4, 0.0, 0.2, 1), background 0.1s linear;
  }
}

@media (max-width: 650px) {
  .mobileMenu-btn-close .hamBox div {
    background-color: var(--primary) !important;
  }
}

.mobileMenu-btn-close .hamBox .bar1 {
  transform: rotate(-45deg) translate(-6px, 10px);
}

.mobileMenu-btn-close .hamBox .bar2 {
  opacity: 0;
}

.mobileMenu-btn-close .hamBox .bar3 {
  transform: rotate(45deg) translate(-6px, -9px);
}


@media (max-width: 650px) {
  .mobileMenu-btn-close .menuText {
    margin-top: 10px;
    color: var(--primary) !important;
  }
}

.nav-logo {
  width: fit-content;
  height: 100%;
  display: flex;
  font-size: 28px;
  font-weight: 700;
  align-items: center;
  margin-left: 50px;
}

@media (max-width: 650px) {
  .nav-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
    align-items: center;
    margin: 0;
  }
}



.landing-page {
  padding: 40px 40px;
  user-select: none;
}

.landing-page .container {
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.landing-page .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-page .content .text-cont {
  width: 60%;
}

.landing-page .content .text-cont .header {
  font-size: 60px;
  color: #18bba2;
  font-weight: 900;
  line-height: 1.1;
}

.landing-page .content .text-cont .description {
  font-size: 16px;
  margin: 30px 0;
  color: #777;
  font-weight: 400;
  width: 80%;
}

.landing-page .content .text-cont .btn {
  font-size: 16px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  padding: 0.6rem 1rem;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
}

.landing-page .content .text-cont .btn-discover {
  min-width: 160px;
  margin-right: 5px;
  color: #fff;
  background-color: var(--first-clr);
}

.landing-page .content .text-cont .btn-discover:hover {
  border-color: var(--first-clr);
  background-color: transparent;
  color: var(--first-clr);
}

.landing-page .content .text-cont .btn-play {
  color: var(--first-clr);
}

.landing-page .content .text-cont .btn-play:hover {
  border-color: var(--first-clr);
  background-color: var(--first-clr);
  color: #fff;
}

.landing-page .content .img-cont {
  width: 35%;
  position: relative;
}

.landing-page .content .img-cont .img {
  width: 100%;
  display: block;
  border-radius: 0 0 0 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}


@media screen and (max-width: 650px) {
  .landing-page .content {
    flex-direction: column;
  }

  .landing-page .content .text-cont,
  .landing-page .content .text-cont .description {
    width: 100%;
  }

  .landing-page .content .text-cont {
    text-align: center;
    width: fit-content;
  }

  .landing-page .content .text-cont .header {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.1;
  }

  .landing-page .content .img-cont {
    width: 80%;
    margin-top: 80px;
  }
}

@media screen and (min-width: 351px) and (max-width: 650px) {
  .desktop-hidden {
    display: inherit;
  }
}

@media screen and (min-width: 351px) and (max-width: 650px) {
  .mobile-hidden {
    display: none;
  }
}

@media (max-width: 650px) {
  .mainButton span {
    font-size: 32px;
    line-height: 40px;
  }

  .heroCard {
    width: 100% !important;
  }
}

@media (max-width: 440px) {
  .mobile-text-change {
    font-size: 8vw !important;
  }
}

@media (max-width: 650px) {
  .mobile-text-change {
    font-size: 36px;
  }
}

@media screen and (min-width: 351px) and (max-width: 650px) {
  .alpha {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
  }
}

.heroCard {
  width: 230px;
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    text-transform: capitalize !important;
    color: #252827 !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-cellContent {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 120% !important;
    /* identical to box height, or 17px */

    text-transform: capitalize !important;

    /* BODY TEXT COLOR */

    color: rgba(0, 0, 0, 0.87) !important;
}
.heading_news {
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}

.container-fluid {
  width: 90%;
  margin: 20px auto 0;
}

.news-eventlist {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
}

@media screen and (min-width: 351px) and (max-width: 768px) {
  .mobile-hidden-news {
    display: none !important;
  }
}

@media screen and (min-width: 651px) and (max-width: 919px) {
  .desktop-hidden-news {
    display: inherit;
  }
}

@media screen and (max-width: 350px) {
  .mobile-hidden-news {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .card-news {
    width: 100%;
  }
}

.card-news {
  border: none;
  background: none;
  height: 100%;
}

.styleCard {
  border-radius: 5px;
  background-color: #fff;
  color: #253b56;
  padding: 20px;
  width: 24%;
  height: fit-content;
  background: #fff;
  border: 1px solid #ccc;
  margin-bottom: 50px;
  transition: 0.3s;
}

@media screen and (max-width: 1100px) {
  .styleCard {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .styleCard {
    width: 100%;
  }
}

@media screen and (max-width: 620px) {
  .container {
    width: 100%;
  }

  .styleCard {
    width: 100%;
  }
}

.card-date {
  display: flex;
  color: #fff;
  margin-top: 10px;
  text-decoration: none;
  padding: 10px 5px;
}

.styleCardContent {
  text-align: left;
  font-size: 18px;
  height: 150px;
}

.styleImage {
  background-color: lightGray;
  border-radius: 5px 5px 0 0;
  height: 200px;
  overflow: hidden;
}

.styleCardTitle {
  font-family: "Lato", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  height: 50px;
  margin: 20px 0px 0px 0px;
}

.styleCardTitle:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #18bba2;
}

.styledateLabel {
  color: #8294aa;
  font-size: 14;
  margin: 0 10px;
}

.styleDescription {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-weight: 300;
  font-size: 18px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



.bw {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.NewsCard:hover {
  cursor: pointer;
}


@media screen and (max-width: 480px) {
  .rec.rec-arrow {
    display: none;
  }
}


/* round buttons on hover */
.rec.rec-arrow:hover {
  background-color: rgb(83, 188, 162) !important;
}


/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec .rec-dot_active {
  height: 5px !important;
  width: 25px !important;
  box-shadow: none !important;
}

.rec .rec-dot {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: rgb(83, 188, 162);
  font-size: 1.3em;
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 10px;
  outline: none;
}

.guIbWC {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 525.922px;
  height: 525.922px;
  padding: 0px;
}

@media screen and (min-width: 351px) and (max-width: 650px) {
  .desktop-hidden-news {
    display: inherit;
  }
}

@media screen and (min-width: 769px) {
  .desktop-hidden-news {
    display: none;
  }
}

.three_dots_menu {
  width: fit-content;
  margin-left: auto;
}

/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 0 5px 0 !important;
} */
.search-click {
    outline: none;
    background-image: url("../../Assets/Images/search-normal.png");
    background-size: 22px;
    background-position: 13px;
    background-repeat: no-repeat;
    width: 30px;
    border: none;
    height: 30px;
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.5s;
}

.search-open {
    outline: none;
    background-image: url("../../Assets/Images/search-normal.png");
    background-size: 22px;
    background-position: 13px;
    background-repeat: no-repeat;
    width: 100%;
    border: none;
    height: 30px;
    padding: 20px 50px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.5s;
}

.search-click:focus {
    width: 300px;
    padding-left: 50px;
}

.search-click {
    position: relative;
    overflow: hidden;
    height: 30px;
}

.search-click input {
    background: transparent;
    border: 1px solid #ccc;
    outline: none;
    position: absolute;
    width: 300px;
    height: 30px;
    left: 0%;
    padding: 10px;
}

.nav_links {
    display: none !important;
}

.full-screen {
    display: none;
}

.search_Con {
    display: flex;
    justify-content: end;
    margin: 10px 0;
    width: 100%;
}

.search_Con_open {
    display: none;
}

.navigation-link {
    text-align: center;
    padding: 10px;
}

@media screen and (max-width: 992px) {
    .responsive-navmenu {
        padding: 20px;
    }

    .full-screen {
        display: block;
    }

    .nav_links {
        display: flex !important;
        height: 100%;
    }

    .responsive-navSearch {
        display: none;
    }

    .mobile-screen {
        display: none;
    }

    .search_Con_open {
        display: flex;
        justify-content: end;
        margin: 10px 10px;
        width: 100%;
        border: 1px solid #e24f57;
        border-radius: 10px;
    }




}
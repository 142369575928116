.footer {
  background-color: rgb(59, 59, 59);

  bottom: 0;
  width: 100%;
}

.innerDiv {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px 0;
}

.innerDItem {
  width: 30%;
}

.middleItem {
  text-align: center;
}

.follow {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  margin: 0 auto 17px;

  text-transform: capitalize;

  /* ICONS COLOR */

  color: var(--icons-color);
}

@media screen and (max-width: 767px) {
  .innerDiv {
    display: block;
  }

  .bottomFooter {
    display: inline-block !important;
    width: 100%;
    text-align: center;
  }

  .innerDItem {
    width: fit-content;
    margin: 1rem auto;
  }

  .imageDiv {
    display: flex;
    justify-content: center;
  }

  .innerDItem h6 {
    text-align: center;
  }
}

.linkDetails:hover {
  color: #fff !important;
}


@media screen and (max-width: 768px) {
  .links {
    font-size: 15px !important;
  }
}

.links {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  padding: 6px;
  font-size: 18px;
}

.iconPath:hover {
  fill: var(--primary);
}

.linkDetails {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  margin: 0 10px;
  text-transform: capitalize;

  color: #FFFFFF;
}

.socialLinks {
  width: 45px;
  height: 45px;
  color: #fff;
  margin: 5px;
}

.bottomFooter {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
}

.copyRight, .bottomLink {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  margin: 0 20px;
  text-align: center;
  text-transform: capitalize;

  color: #FFFFFF;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}
hr {
  width: 80%;
  margin: 0 auto;
  color: #fff;
  border: 1px solid;
}

.program_card_details {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  height: fit-content;
  margin: 20px auto;
  padding: 15px;
  text-align: center;
  width: 75%;
}

.card_Heading_landing {
  color: var(--primary);
  font-size: 32px;
  line-height: 120%;
  margin: 20px 0;
  text-transform: capitalize;
}

.card_text_landingPage {
  text-align: left !important;
  color: var(--body-text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 10px auto;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 90%;
}

.card_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  width: 80%;
  margin: 10px auto;
  overflow: hidden;
  color: var(--body-text-color);
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading {
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}

.subtitle {
  margin: 20px auto 40px !important;
}

.Programs {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 470px;
}

@media only screen and (max-width: 768px) {
  .Programs {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 660px;
  }

  h2 {
    font-size: 20px;
  }

  hr {
    width: 100%;
  }
}

@media only screen and (width: 768px) {
  .Programs {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 520px;
  }
}

@media only screen and (width: 280px) {
  .Programs {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 840px;
  }
}

.row {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  flex-flow: wrap;
}

.programs_card {
  width: 24%;
  height: 400px;
  background: #3b3b3b;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-bottom: 50px;
  transition: 0.3s;
}

.programs_card:hover {
  cursor: pointer;
  background-color: var(--secondary);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1000px) {
  .programs_card {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .programs_card {
    height: 350px;
  }

  .card-details {
    height: 110px !important;
  }

  .explore-more {
    display: block !important;
    color: var(--primary);
  }
}

.cardBody {
  padding: 30px 20px;
  text-align: center;
  font-size: 18px;
}

.card-header {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 110px;
  padding: 30px 10px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;

  color: #fff;
  border: none;
}

.card-details {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  font-weight: 300;
  color: #fff !important;
  height: fit-content;
  font-size: 18px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.explore-more {
  display: none;
}

.programs_card:hover .explore-more {
  display: block;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  transition: 1s;
}

.explore-more:hover {
  color: var(--primary);
}

@media screen and (max-width: 768px) {
  .cardBody {
    width: 100%;
    margin: 0;
  }
}


@media screen and (max-width: 620px) {
  .container {
    width: 100%;
  }

  .heading {
    padding: 20px;
    font-size: 20px;
    margin: 0;
  }

  .subtitle {
    margin: 10px 0 !important;
    width: 100% !important;
  }

  .programs_card {
    width: 80%;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 60%;
  width: 800px !important;
}
.back-to-top-btn {
    position: fixed;
    width: fit-content;
    left: 90%;
    bottom: 45px;
    height: fit-content;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #18bba2;
    border-radius: 50%;
    opacity: .5;

}

@media (max-width: 650px) {
    .back-to-top-btn {
        left: 70%;
        bottom: 25px;

    }
}
.Library-btn {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background: #fff;
  border: 1px solid #fff;
  color: var(--secondary);
  border-radius: 5px;
}

.Library-btn:hover {
  color: var(--secondary);
}
.form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: 100%;
    border: none;
}

.column {
    float: left;
    width: 25%;
    padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
    margin: 0 -5px;
}

/* Clear floats after the columns */
/* .row:after {
    content: "";
    display: table;
    clear: both;
} */

/* Responsive columns */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }
}

/* 
Style the counter cards
.faculty_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
} */



.sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
}

.active_sidebar {
    left: 0 !important;
    width: 280px;
    height: 100%;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
    transition: 0.5s;
}

.sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
}

.sd-body {
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}

.sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}

.sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
}

.sd-body ul li .sd-link {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
.heading-LAC {
    font-family: 'Trade Gothic W01 Light', Arial, Helvetica, Verdana, sans-serif;
}


.galleryImage:hover {
    opacity: 0.7;
}

.imagesBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}
.content {
    padding: 7rem 0;
}

h3 {
    font-size: 2rem;
}

h2, h3 {
    font-weight: 700;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: Poppins, sans-serif;
}

.border-left {
    padding-left: 70px !important;
    border-left: 1px solid #dee2e6 !important;
}

@media (max-width: 991.98px) {
    .border-left {
        border-left: none !important;
        padding-left: 0 !important;
    }
}

.card_row_statics {
    display: flex;
    flex-wrap: wrap;
}

.card_row_statics>* {
    flex: 1 1 160px;
}
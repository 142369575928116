.course-card {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
}


.course-card:hover {
    cursor: pointer;
}


.course-card-header img {
    width: 100%;
    height: 170px;
    object-fit: cover;

}


.course-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 200px;
}

.course-card-title {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0 0 10px 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.tag-teal {
    background-color: #18bba2;
}

.tag-purple {
    background-color: #5e76bf;
}

.tag-pink {
    background-color: #cd5b9f;
}

.course-card-body p {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 40px;
    width: 100%;
}

.date {
    display: flex;
    margin-top: auto;
}

.date img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.date-info small {
    color: #545d7a;
}

@media (min-width: 1200px) {
    .css-1f2kw6w-MuiContainer-root {
        max-width: 100% !important;
    }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: var(--admin);
    color: #fff !important
}
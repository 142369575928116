.mainHeader {
    width: 100%;
    height: 100px;
    background: #252827;
    padding: 10px;
    z-index: 999;
}

@media screen and (max-width: 992px) {
    .mainHeader {
        display: none;
    }
}

.main-block {
    padding: 16px;
    max-width: 900px;
    margin: 0 auto;
    font-size: 18px;
}

.fixedTop {
    width: 100%;
    padding: 12px 18px;
    position: fixed;
    opacity: .8;
    top: 0;
}

.fixedTop+.main-block {
    padding-top: 102px;
}
.main {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.flex_row {
    justify-content: center;
    display: flex;
    padding-top: 20px;
}

.glimpse_thumbnail {
    padding: 0 10px;
    width: fit-content;
    height: fit-content;
}

.clicked {
    border: none !important;
    border-radius: 4px;
}

.thumbImg {
    border-radius: 4px;
}

video {
    border-radius: 4px;
}

.guIbWC {
    height: fit-content !important;
}